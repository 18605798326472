import imageOne from "../assets/images/chooseus1.webp";
import imageTwo from "../assets/images/chooseus2.webp";
import imageThree from "../assets/images/chooseus3.webp";
import customdevelopment from "../assets/images/png/cutomedevelopment.png";
import mobiledevelopment from "../assets/images/png/mobile-development.png";
import webdevelopment from "../assets/images/png/webdevelopment.png";
import artificila from "../assets/images/png/artificial-intelligence.png";
import arvr from "../assets/images/png/arvr.png";
import machinelearning from "../assets/images/png/machine-learning.png";
import webPortfolio1 from '../assets/images/web_projects/1.webp'
import webPortfolio2 from '../assets/images/web_projects/2.webp'
import webPortfolio3 from '../assets/images/web_projects/3.webp'
import webPortfolio4 from '../assets/images/web_projects/4.webp'
import webPortfolio5 from '../assets/images/web_projects/5.webp'
import webPortfolio6 from '../assets/images/web_projects/6.webp'
import appPortfolio1 from '../assets/images/app_projects/1.webp'
import appPortfolio2 from '../assets/images/app_projects/2.webp'
import appPortfolio3 from '../assets/images/app_projects/3.webp'
import appPortfolio4 from '../assets/images/app_projects/4.webp'

const baseUrl = process.env.PUBLIC_URL;

export function publicUrlFor(path) {
  return baseUrl + "/assets/" + path;
}

export const companyDetails = {
  name: "45Caliber AI",
  number: "+916302956220",
  address: "Hyderabad, India",
  email: "connect@45caliber.com",
};


export function loadScript(src, fromPublic) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");

    script.src =
      fromPublic === undefined || fromPublic == null || fromPublic
        ? publicUrlFor(src)
        : src;

    script.addEventListener("load", function () {
      resolve();
    });
    script.addEventListener("error", function (e) {
      reject(e);
    });
    document.body.appendChild(script);
    document.body.removeChild(script);
  });
}

export const ourServices = [
  {
    image: artificila,
    heading: "AI Development",
    shortDescription:
      "Tailored AI solutions that power your business processes and enhance decision-making.",
    description:
      "At the heart of every industry revolution, artificial intelligence (AI) is enabling businesses to achieve unprecedented levels of innovation and efficiency. Our AI Development services provide end-to-end solutions that help you harness the power of AI to automate tasks, analyze data, and make smarter business decisions.",
  },
  {
    image: machinelearning,
    heading: "ML Solutions",
    shortDescription:
      "Advanced algorithms and predictive models designed to optimize your operations and customer experiences.",
    description:
      "Unlock the true potential of your data with our Machine Learning (ML) Solutions. In today’s data-driven world, machine learning is critical for making sense of vast amounts of information, automating complex processes, and delivering actionable insights.",
  },
  {
    image: arvr,
    heading: "VR & AR Development",
    shortDescription:
      "Immersive experiences that blend the digital and physical worlds, ideal for training, entertainment, and marketing.",
    description:
      "The future of interactive technology lies in Virtual Reality (VR) and Augmented Reality (AR). Our VR & AR Development services are designed to create immersive, interactive experiences that blend the digital and physical worlds, transforming how users interact with your products, services, and content.",
  },
  {
    image: customdevelopment,
    heading: "Custom Software Development",
    shortDescription:
      "Bespoke software solutions that cater specifically to your business requirements and objectives.",
    description:
      "In today’s fast-paced digital environment, off-the-shelf software often doesn’t meet the specific needs of growing businesses. Our Custom Software Development services are designed to address these unique requirements, delivering high-quality, scalable solutions that are tailored to your exact specifications.",
  },
  {
    image: mobiledevelopment,
    heading: "Mobile App Development",
    shortDescription:
      "User-friendly mobile applications designed to engage and retain your customers.",
    description:
      "In an increasingly mobile-first world, a robust, feature-rich mobile application is essential for businesses aiming to improve user engagement and streamline operations. Our Mobile App Development services offer tailored solutions for both iOS and Android platforms, designed to deliver a seamless, intuitive user experience.",
  },
  {
    image: webdevelopment,
    heading: "3D Web Development",
    shortDescription:
      "Innovative web experiences that captivate visitors with stunning graphics and interactivity.",
    description:
      "The web is no longer a two-dimensional space. With the growing capabilities of modern browsers and graphics engines, 3D web development is transforming how businesses present content and engage with users. Our 3D Web Development services are designed to deliver rich, interactive experiences that captivate visitors and provide a unique user interface.",
  },
];

export const whyChooseUs = {
  mainHeading:
    "At 45Caliber AI, we understand that choosing the right technology partner is crucial for your business's success. Here’s why we stand out in a crowded marketplace",
  details: [
    {
      iamge: imageOne,
      heading: "Tailored Solutions",
      description:
        "We believe that one size does not fit all. Our custom solutions are meticulously crafted to align with your specific business needs and objectives. Whether you require bespoke software, mobile applications, or comprehensive cloud services, we work closely with you to ensure our offerings are tailored to meet your unique challenges and goals. This personalized approach ensures that you receive the most effective solutions that drive meaningful results.",
    },
    {
      iamge: imageTwo,
      heading: "Expert Team",
      description:
        "Our interdisciplinary team comprises seasoned professionals with diverse expertise across various domains. From software developers and UX/UI designers to data scientists and cybersecurity specialists, our collective knowledge ensures that you receive the best guidance and support at every stage of your project. We are committed to leveraging our experience to help you navigate the complexities of technology and achieve your desired outcomes.",
    },
    {
      iamge: imageThree,
      heading: "Commitment to Innovation",
      description:
        "In today’s fast-paced digital landscape, staying ahead of the curve is essential. At 45Caliber AI, we are dedicated to continual innovation. We adopt the latest technologies and industry best practices to provide you with solutions that not only meet your current needs but also anticipate future trends. Our forward-thinking approach allows your business to remain agile and responsive to market changes.",
    },
  ],
};

export const ourlandingServices = {
  webServices: [
    {
      image: require('../assets/images/png/laptop.png'),
      heading: "E-commerce Solutions",
      description: "Boost your sales with custom-designed online stores.",
    },
    {
      image: require('../assets/images/png/maintenance.png'),
      heading: "Website Maintenance",
      description: "Keeping your site updated and running smoothly.",
    },
    {
      image: require('../assets/images/png/computer.png'),
      heading: "SEO Optimization",
      description:
        "Ensuring your site is easily found with on-page SEO best practices.",
    },
    {
      image: require('../assets/images/png/web-design.png'),
      heading: "Custom Web Design",
      description:
        "Tailored websites that reflect your brand and engage your target audience",
    },
    {
      image: require('../assets/images/png/responsive-design.png'),
      heading: "Responsive Design",
      description:
        "Seamless user experience across all devices—desktop, tablet, and mobile.",
    },
  ],
  appServices: [
    {
      image: require('../assets/images/png/application.png'),
      heading: "Custom App Development",
      description:
        "Tailored apps designed for your unique business needs.",
    },
    {
      image: require('../assets/images/png/cloud-sync.png'),
      heading: "Cross-Platform Solutions",
      description:
        "Apps that perform seamlessly across iOS, Android, and web platforms.",
    },
    {
      image: require('../assets/images/png/user-experience.png'),
      heading: "UX/UI Design",
      description:
      "Intuitive and attractive designs for an outstanding user experience.",
    },
    {
      image: require('../assets/images/png/maintenance.png'),
      heading: "Backend Development",
      description: "Secure and scalable backend services to support your app.",
    },
    {
      image: require('../assets/images/png/maintenance.png'),
      heading: "App Maintenance and Updates",
      description: "Ensuring your app stays up-to-date and fully functional.",
    },
  ],
};


// portfolio images (web development)
export const webPortfolio = [
  {
    id: 1,
    img: webPortfolio1,
    title: "FE-Finance",
  },
  {
    id: 2,
    img: webPortfolio2,
    title: "Ocxee",
  },
  {
    id: 3,
    img: webPortfolio3,
    title: "Leaderbridge",
  },
  {
    id: 4,
    img: webPortfolio4,
    title: "Gigzio",
  },
  {
    id: 5,
    img: webPortfolio5,
    title: "FE Group",
  },
  {
    id: 6,
    img: webPortfolio6,
    title: "V Talkz",
  },
];

// portfolio images (app development)
export const appPortfolio = [
  {
    id: 1,
    img: appPortfolio1,
    title: "PartEx",
  },
  {
    id: 2,
    img: appPortfolio2,
    title: "Find A Driver",
  },
  {
    id: 3,
    img: appPortfolio3,
    title: "Artisan Express",
  },
  {
    id: 4,
    img: appPortfolio4,
    title: "House of Deliverance",
  },
];